import { skipHydrate } from 'pinia'
import type { AuthCredentials } from '@base/types'
import type { RouteLocationNormalized } from 'vue-router'

export const useAuth = defineStore('auth', () => {
  const client = useSupabaseClient()
  const authUser = useSupabaseUser()
  const registerWithPassword = async (credentials: AuthCredentials, metadata?: Record<string, any>) => {
    if (!credentials.email || !credentials.password)
      return

    try {
      await $fetch('/api/user/create-account', {
        method: 'POST',
        body: {
          email: credentials.email,
          password: credentials.password,
          redirectTo: `${useRequestURL().origin}/email-verified`,
          metadata,
        },
        headers: useRequestHeaders(['cookie']),
      })
    }
    catch (e: any) {
      console.error(e)
      return createError(e.message)
    }
  }

  const resolveSession = async (url: RouteLocationNormalized) => {
    // check if the url contains access and refresh token
    const urlHash = new URLSearchParams(url.hash.replace('#', ''))
    const access_token = urlHash.get('access_token')
    const refresh_token = urlHash.get('refresh_token')
    if (access_token && refresh_token) {
      // set the tokens to the supabase client
      await client.auth.setSession({
        access_token,
        refresh_token,
      })
    }
  }

  const loginWithPassword = async (credentials: AuthCredentials) => {
    if (!credentials.email || !credentials.password)
      return
    const { error } = await client.auth.signInWithPassword(credentials)
    if (error)
      throw new Error(error.message)

    if (!authUser.value)
      return createError('Auth Failed')
  }

  const loginWithProvider = async (provider: 'discord' | 'google' | 'facebook') => {
    let options = {
      redirectTo: `${useRequestURL().origin}`,
      scope: '',
    }
    if (provider === 'discord') {
      options = {
        ...options,
        scope: 'connections',
      }
    }

    const { error } = await client.auth.signInWithOAuth({ provider, options })
    if (error)
      return error

    if (!authUser.value)
      return createError('Auth Failed')
  }

  const sendResetPasswordEmail = async (email: string) => {
    try {
      // await client.auth.resetPasswordForEmail(email, {
      //   redirectTo: `${useRequestURL().origin}/update-password`,
      // })
      await $fetch('/api/user/reset-password', {
        method: 'POST',
        body: {
          redirectTo: `${useRequestURL().origin}/update-password`,
          email,
        },
        headers: useRequestHeaders(['cookie']),
      })
    }
    catch (e: any) {
      console.error(e)
      return createError(e?.message)
    }
  }

  // to be used with forgotten password emails. Not needed to enter old password
  const changeForgottenPassword = async (newPassword: string) => {
    const { error } = await client.auth.updateUser({ password: newPassword })
    if (error)
      return createError(error.message)
  }

  // to be used to change password when user knows old password
  const setNewPassword = async (credentials: AuthCredentials, newPassword: string) => {
    const { $t } = useI18n()

    const errorLogin = await loginWithPassword(credentials)
    if (errorLogin)
      return createError(String($t('components.validations.invalidOldPassword')))

    try {
      await $fetch('/api/user/update-password', {
        method: 'PATCH',
        body: {
          newPassword,
        },
        headers: useRequestHeaders(['cookie']),
      })
    }
    catch (e: any) {
      console.error(e)
      return createError(e?.message)
    }
  }

  const logout = async () => {
    const { $switchRoute } = useI18n()

    const { error } = await client.auth.signOut()
    if (error)
      throw createError(error.message)
    $switchRoute('/')
  }

  const getSession = async () => {
    return (await client.auth.getSession()).data.session
  }

  const markUserAsAdmin = async () => {
    const metadata = authUser.value?.user_metadata ?? {}
    if (metadata.isAdmin)
      return
    metadata.isAdmin = true
    const { error } = await client.auth.updateUser({ data: metadata })
    if (error)
      return createError(error.message)
  }

  return {
    authUser,
    loginWithPassword,
    loginWithProvider,
    registerWithPassword,
    sendResetPasswordEmail,
    changeForgottenPassword,
    setNewPassword,
    logout,
    getSession,
    markUserAsAdmin,
    resolveSession: skipHydrate(resolveSession),
  }
})
