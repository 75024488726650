import { useToast } from 'vue-toastification'
import type { Bank } from '@develit-io/fubex-exchange-prisma'
import type { CurrencyDeep, OurBankAccount } from '@base/types'
import countries from '@base/constants/countries'

const toast = useToast()

export const useCodes = defineStore('codes', () => {
  const { $getLocale } = useI18n()
  // const languages = useState<Language[]>(() => [])
  const banks = useState<Bank[]>('banks', () => [])
  const currencies = useState<CurrencyDeep[]>('currencies', () => [])
  const depositChains = useState<string[]>('depositChains', () => [])

  // const fetchLanguages = async (force?: boolean) => {
  //   if (!force && languages.value.length)
  //     return
  //   const data = await $fetch('/api/codes/languages', { method: 'GET' })
  //   languages.value = data
  // }

  // const getLanguageByCode = (code: string) =>
  //   languages.value.find(l => l.code === code)

  const fetchBanks = async (force?: boolean) => {
    if (!force && banks.value.length)
      return
    const data = await $fetch('/api/codes/banks', { method: 'GET' })
    banks.value = data
  }

  const getBankByCode = (code: string) =>
    banks.value.find(b => b.bankCode === code)

  const getBankById = (id: number) =>
    banks.value.find(b => b.bankId === id)

  const fetchCurrencies = async (force?: boolean) => {
    if (!force && currencies.value.length)
      return
    const data = await $fetch('/api/codes/currencies', { method: 'GET' })
    currencies.value = data
  }

  const getCurrencyByCode = (code: string) => {
    currencies.value.find(c => c.code === code)
  }

  const initCodes = async (force?: boolean) => {
    if (force || !banks.value.length || !currencies.value.length)
      await Promise.all([fetchBanks(force), fetchCurrencies(force)])
  }

  const fetchDepositChains = async () => {
    const chains = await $fetch<string[]>('/api/codes/deposit-chains', { method: 'GET' })
    if (!chains) {
      return toast.error(`Couldn't retrieve deposit chains data.`)
    }

    depositChains.value = chains
  }

  const getDepositChain = async (currencyCode: string, chainId: string) => {
    const chain = await $fetch('/api/deposit/deposit-chain', { method: 'GET',
      query: {
        currencyCode,
        chainId,
      },
    })

    return chain
  }

  const ourBanks: OurBankAccount[] = [
    {
      bankName: 'FIO banka',
      bankAccountNumber: '2301924599/2010',
      iban: 'CZ0820100000002301924599',
      currency: 'CZK',
    },
    {
      bankName: 'FIO banka',
      bankAccountNumber: '2401924609/2010',
      iban: 'CZ7320100000002401924609',
      currency: 'EUR',
    },
  ]

  const getOurBanksByCurrency = (currencyCode: string) => {
    return ourBanks.filter(bank => bank.currency === currencyCode)
  }

  return {
    banks,
    currencies,
    initCodes,
    getCurrencyByCode,
    fetchBanks,
    getBankByCode,
    getBankById,
    fetchCurrencies,
    getDepositChain,
    ourBanks,
    getOurBanksByCurrency,
    fetchDepositChains,
    depositChains,
    countries: countries.sort((a, b) => (a.priority ?? 100) - (b.priority ?? 100)).map(c => ({
      ...c,
      translatedName: computed(() => getLocalizedCountryName(c.code2, $getLocale())),
    })),
  }
})
