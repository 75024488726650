import revive_payload_client_DYlCFYse8H from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_1CJ3SFqgvT from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_EucOHz5wEa from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_vb4LtyqGvh from "/vercel/path0/node_modules/.pnpm/nuxt-sentry@0.4.2_magicast@0.3.5_rollup@4.34.2_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-sentry/dist/runtime/sentry.client.mjs";
import supabase_client_AC0y6M9SvN from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _01_plugin_2gC2qbEWQy from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.74.0_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4._mldeg2gezxf52o5zphcglbnsbm/node_modules/nuxt-i18n-micro/dist/runtime/plugins/01.plugin.js";
import navigation_repaint_client_r7rwplqV1x from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dyn3zAcUOh from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Lg6NbeDAQc from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ervtk0jFSq from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.34.2_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/youcz/.nuxt/components.plugin.mjs";
import prefetch_client_OEfJpYCjHg from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.19.0_jiti@2.4.2__ior_7nz6goiwu5iddija4wxu7pi4ru/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nDfMl8ePO5 from "/vercel/path0/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.5.1_magicast@0.3.5_rollup@4.34.2_webpack@5.97.1_esbuild@0.24.2_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/youcz/.nuxt/unocss.mjs";
import slideovers_RnLIxLWuzh from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_qrcode@1.5.4_rollup@4.34.2__pe6gt4l5wbsxvsdtiom2fuqqbi/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Rr8kyhTMmn from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_qrcode@1.5.4_rollup@4.34.2__pe6gt4l5wbsxvsdtiom2fuqqbi/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_kfPOFOWJPS from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_qrcode@1.5.4_rollup@4.34.2__pe6gt4l5wbsxvsdtiom2fuqqbi/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_duGY9uVjuA from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.34.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_E3qlBjLc9G from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4.2_ter_ltxyetu4obp5s2nmc4pzxpoo2q/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auto_animate_XIZCskOXXg from "/vercel/path0/layers/base/plugins/auto-animate.ts";
import chart_client_bcfhrmN7hg from "/vercel/path0/layers/base/plugins/chart.client.ts";
import use_head_sEf1F6ycmZ from "/vercel/path0/layers/base/plugins/use-head.ts";
import bottomSheet_client_C55D02kKcz from "/vercel/path0/layers/components/fubex-components/plugins/bottomSheet.client.ts";
import validation_H6BESNSSPe from "/vercel/path0/layers/components/fubex-components/plugins/validation.ts";
import vue_toastification_client_OdKXnwPIgP from "/vercel/path0/layers/components/fubex-components/plugins/vue-toastification.client.ts";
import _05_hooks_vwwJfbVDx2 from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.74.0_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4._mldeg2gezxf52o5zphcglbnsbm/node_modules/nuxt-i18n-micro/dist/runtime/plugins/05.hooks.js";
import _03_define_ZM350WOSx8 from "/vercel/path0/node_modules/.pnpm/nuxt-i18n-micro@1.74.0_magicast@0.3.5_rollup@4.34.2_vite@6.0.11_@types+node@22.13.1_jiti@2.4._mldeg2gezxf52o5zphcglbnsbm/node_modules/nuxt-i18n-micro/dist/runtime/plugins/03.define.js";
export default [
  revive_payload_client_DYlCFYse8H,
  unhead_1CJ3SFqgvT,
  router_EucOHz5wEa,
  sentry_client_vb4LtyqGvh,
  supabase_client_AC0y6M9SvN,
  _01_plugin_2gC2qbEWQy,
  navigation_repaint_client_r7rwplqV1x,
  check_outdated_build_client_dyn3zAcUOh,
  chunk_reload_client_Lg6NbeDAQc,
  plugin_vue3_Ervtk0jFSq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OEfJpYCjHg,
  plugin_nDfMl8ePO5,
  unocss_MzCDxu9LMj,
  slideovers_RnLIxLWuzh,
  modals_Rr8kyhTMmn,
  colors_kfPOFOWJPS,
  plugin_client_duGY9uVjuA,
  plugin_E3qlBjLc9G,
  auto_animate_XIZCskOXXg,
  chart_client_bcfhrmN7hg,
  use_head_sEf1F6ycmZ,
  bottomSheet_client_C55D02kKcz,
  validation_H6BESNSSPe,
  vue_toastification_client_OdKXnwPIgP,
  _05_hooks_vwwJfbVDx2,
  _03_define_ZM350WOSx8
]